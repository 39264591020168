import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import frameOne from "../../../logosAndImages/Frame 265.svg";
import frameTwo from "../../../logosAndImages/Frame 266.svg";
import frameThree from "../../../logosAndImages/Frame 267.svg";
import amala from "../../../logosAndImages/amala.jpg";
import gourav from "../../../logosAndImages/Gourav.jpg";
import ritik from "../../../logosAndImages/ritik.jpg";
import priya from "../../../logosAndImages/Priya Bhatt.png";
import JobCard from "./jobCard";
import FeedbackCard from "./feedbackCard";
import people from "../../../logosAndImages/MicrosoftTeams-image.png";
import { useEffect, useState, useRef } from "react";
import Slider from "react-slick";

const Career = () => {
  const [currentSlide, setCurrentSlide] = useState();
  const [slides, setSlides] = useState([]);
  const sliderRef = useRef(null);
  const [duplicate, setDuplicate] = useState([]);

  const engineerJobs = [
    {
      designation: "React Native Engineer",
      role: "Engineer",
      description:
        "You will be in charge of the design,development, testing and deployment of new services and functionalities of the application.",
      link: "https://forms.gle/nKVSYhsVv97Cf8Rn9",
    },
    {
      designation: "Backend Engineer",
      role: "Engineer",
      description:
        "You will be part of team who majorly works on building and updating the existing APIs build in NodeJS.",
      link: "https://forms.gle/nKVSYhsVv97Cf8Rn9",
    },
  ];

  const internJobs = [
    {
      designation: "Software Developer Intern",
      role: "Intern",
      description:
        "Students, college grads or professionals all are welcomed for this opportunity. You will have the flexibility of choose between working on frontend or backend.",
      link: "https://forms.gle/nKVSYhsVv97Cf8Rn9",
    },
    {
      designation: "Marketing Intern",
      role: "Intern",
      description:
        "We are growing startup, to keep up the high mode on for the people we are consistently looking for people with amazing marketing ideas and out of box thinking.",
      link: "https://forms.gle/nKVSYhsVv97Cf8Rn9",
    },
    {
      designation: "Design Intern",
      role: "Intern",
      description:
        "We love to provide the users with the best designs and amazing user experience, for that we are expanding our design teams. If we love your work, will go for full time opportunity.",
      link: "https://forms.gle/nKVSYhsVv97Cf8Rn9",
    },
    {
      designation: "Social Media Intern",
      role: "Intern",
      description:
        "We maintain a good social media presence to keep the users updated about whats going on, and keep the high mode on!. So we are expanding our social media team to accelerate it even further.",
      link: "https://forms.gle/nKVSYhsVv97Cf8Rn9",
    },
  ];

  const employeeReview = [
    {
      name: "Ritik Patil",
      designation: "Software Developer Intern",
      review:
        "I received a lot of guidance throughout my coding experience at Highon. I learnt new ways to finish my tasks. I never felt the pressure because of pre-planned work allocation.",
      img: ritik,
    },
    {
      name: "Amala Mary",
      designation: "Content Writer",
      review:
        "Highon is a great place to work with, primarily because of the exuberant culture and secondarv. the convenient the working hours. I was always given a lot of intervals after submitting my work.It has been a splendid experience for me.",
      img: amala,
    },

    {
      name: "Gaurav Sharma",
      designation: "Software Developer",
      review:
        "This is the most complex task I have ever tried. It is fascinating to work with counterparts who are helpful and interactive. For great learning opportunities, would say Highon is the best work environment.",
      img: gourav,
    },
    {
      name: "Priya Bhatt",
      designation: "Designer",
      review:
        "My mentors at Highon helped me immensely in expanding my knowledge from Figma to Adobe AI, PS. The co-founders are very friendly and there is no coerced schedule, making my day at work productive, stress-free, and pleasant.",
      img: priya,
    },
  ];

  const icons = [
    { source: frameOne, alternate: "icons", link: "" },
    { source: frameTwo, alternate: "icons", link: "" },
    { source: frameThree, alternate: "icons", link: "" },
  ];

  // for initializing current slide value
  useEffect(() => {
    setCurrentSlide(Math.floor(employeeReview.length) - 1);
  }, []);

  // Get all slides and set them in state
  useEffect(() => {
    const duplicateSlides = document.querySelectorAll(".slick-cloned");
    const realSlides = document.querySelectorAll(
      ".slick-slide:not(.slick-cloned)"
    );
    setDuplicate(duplicateSlides);
    setSlides(realSlides);
  }, [window.innerWidth]);

  // callback function on slide change to set current slide
  const handleAfterChange = (index) => {
    setCurrentSlide(index);
  };

  // settings for carousel
  const settings = {
    centerMode: true,
    infiniteLoop: employeeReview.length > 1 ? true : false,
    centerPadding: "50px",
    arrows: false,
    dots: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: employeeReview.length >= 3 ? 3 : 1,
    slidesToScroll: 1,
    dotsClass: "slick-dots visible",
    useKeyboardArrows: true,
    swipeToSlide: true,
    autoFocus: true,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "10px",
          initialSlide: 0,
          afterChange: handleAfterChange,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          centerPadding: "5px",
          initialSlide: 0,
          afterChange: handleAfterChange,
        },
      },
      {
        breakpoint: 720,
        settings: {
          infinite: true,
          slidesToShow: 1,
          initialSlide: 0,
          afterChange: handleAfterChange,
        },
      },
    ],
  };

  // scaling down other slides and scaling up current slide
  useEffect(() => {
    slides.forEach((slide, index) => {
      if (slide.className.includes("slick-center")) {
        slide.style.opacity = 1;
        slide.style.transform = "scale(1)";
      } else if (slide.className.includes("slick-active slick-cloned")) {
        slide.style.opacity = 0.6;
        slide.style.transform = "scale(0.8)";
      } else {
        slide.style.opacity = 0.6;
        slide.style.transform = "scale(0.8)";
      }
    });
    duplicate.forEach((item, index) => {
      item.style.opacity = 0.6;
      item.style.transform = "scale(0.8)";
    });
  }, [currentSlide, slides]);

  return (
    <div className="careerContainer">
      <div className="headerContainer">
        <div className="textHeader">
          <h2>Connect</h2>
          <h2>lives with us</h2>
          <div className="headerPara">
            <p>
              We are on a mission to make social lives better and connect people
              of similar interests to vibe with one accord. At Highon, we always
              appreciate talented and motivated individuals on our team. We are
              the first-ever social vibing platform. But to make it the best, we
              will need your help.
            </p>
            <p>Love what we do?</p>
            <p>Let's find a spot for you in the Highon family.</p>
          </div>
        </div>
        <div className="imgHeader">
          <div className="overlayImg">
            {icons.map((item, index) => {
              return (
                <div className={"overlayIcon" + index}>
                  <img
                    src={item.source}
                    alt={item.alternate}
                    className="connectIcon"
                  />
                </div>
              );
            })}
          </div>
          <img src={people} alt="connectwithusimage" />
        </div>
      </div>
      <div className="careerHeader" id="jobOption">
        <div className="textContainer">
          <h2>Start doing work that matters</h2>
          <p>
            Our philosophy is simple - hire a team of diverse, passionate people
            and foster a culture that empowers you to do your best work.
          </p>
        </div>
      </div>
      <div className="jobBoard">
        <div className="jobContent">
          <div className="jobTitle">
            <h3>Engineer</h3>
            <h4>Open position for engineers</h4>
          </div>
          <div className="jobDescription">
            {engineerJobs.map((item) => {
              return <JobCard item={item} />;
            })}
          </div>
        </div>
        <div className="internJobContent">
          <div className="jobTitle">
            <h3>Intern</h3>
            <h4>Open position for Interns</h4>
          </div>
          <div className="jobDescription">
            {internJobs.map((item) => {
              return <JobCard item={item} />;
            })}
          </div>
        </div>
      </div>
      <div className="feedbackContainer">
        <Slider ref={sliderRef} {...settings}>
          {employeeReview.length === 0
            ? "Currently no openings"
            : employeeReview.map((item) => {
                return <FeedbackCard item={item} />;
              })}
        </Slider>
      </div>
    </div>
  );
};

export default Career;
