import * as React from "react";
import '../testimonial/testimonial.css'
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27.185 27.185"
    {...props}
    className="arrows"
  >
    <g
      id="Icon_ionic-ios-arrow-dropleft"
      data-name="Icon ionic-ios-arrow-dropleft"
      transform="translate(0 0)"
    >
      <path
        id="Path_35"
        data-name="Path 35"
        d="M12.535,10.35a1.266,1.266,0,0,1,1.784,0L20.554,16.6a1.26,1.26,0,0,1,.039,1.738L14.45,24.5a1.259,1.259,0,1,1-1.784-1.778l5.215-5.293-5.352-5.3A1.26,1.26,0,0,1,12.535,10.35Z"
        transform="translate(-1.903 -3.841)"
        fill="#00b7f4"
      />
      <path
        id="Path_36"
        data-name="Path 36"
        d="M16.968,3.375A13.593,13.593,0,1,1,3.375,16.968,13.591,13.591,0,0,1,16.968,3.375ZM8.838,25.1A11.5,11.5,0,1,0,25.1,8.838,11.5,11.5,0,1,0,8.838,25.1Z"
        transform="translate(-3.375 -3.375)"
        fill="#00b7f4"
      />
    </g>
  </svg>
);
export default SVGComponent;
