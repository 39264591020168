import React, { useState, useRef, useEffect } from "react";
import "./testimonial.scss";
import "./testimonial.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TestimonialCard from "./card";
import LeftArrow from "../Asset/leftArrow";
import RightArrow from "../Asset/rightArrow";
import Sahithi from "../Asset/testimonial/Sahithi.png";
import Diksha from "../Asset/testimonial/Diksha.png";
import Sumanth from "../Asset/testimonial/Sumanth.png";
import Judith from "../Asset/testimonial/Judith.png";

const employeeReview = [
  {
    name: "Sahithi Antony",
    location: "Hyderabad",
    review:
      "Highon's vibrant community and captivating feed consistently deliver a diverse set of content. Its soo refreshing!",
    img: Sahithi,
  },
  {
    name: "Diksha",
    location: "New Delhi",
    review:
      "Usually apps are bombarded with the notifications, making it impossible to see them all. Thanks to Highon, notifications are not cluttered anymore!",
    img: Diksha,
  },
  {
    name: "Judith",
    location: "Hyderabad",
    review:
      "Masking myself with creative zinku NFTs, anonymously chatting with like-minded people on secret chats is my new pass time. Totally love it! ❤️",
    img: Judith,
  },
  {
    name: "Sumanth",
    location: "Hyderabad",
    review:
      "I cant wait for Activity tab to be released! Just want to go out and meet new people who shares the same vibe!",
    img: Sumanth,
  },
];

const Testimonial = () => {
  const [currentSlide, setCurrentSlide] = useState();
  const [slides, setSlides] = useState([]);
  const sliderRef = useRef(null);
  const [duplicate, setDuplicate] = useState([]);

  const handleAfterChange = (index) => {
    setCurrentSlide(index);
  };

  const handleForward = () => {
    sliderRef.current.slickNext();
  };
  const handleBackward = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    setCurrentSlide(Math.floor(employeeReview.length) - 1);
  }, []);

  useEffect(() => {
    const duplicateSlides = document.querySelectorAll(".slick-cloned");
    const realSlides = document.querySelectorAll(
      ".slick-slide:not(.slick-cloned)"
    );
    setDuplicate(duplicateSlides);
    setSlides(realSlides);
  }, [window.innerWidth]);

  const settings = {
    centerMode: true,
    infiniteLoop: employeeReview.length > 1 ? true : false,
    centerPadding: "350px 40px",
    arrows: false,
    dots: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: "slick-dots visible",
    useKeyboardArrows: true,
    swipeToSlide: true,
    autoFocus: true,
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerPadding: "300px 40px",
          afterChange: handleAfterChange,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          centerPadding: "260px 40px",
          afterChange: handleAfterChange,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "200px 40px",
          afterChange: handleAfterChange,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          centerPadding: "140px 40px",
          afterChange: handleAfterChange,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          centerPadding: "10px 30px ",
          afterChange: handleAfterChange,
        },
      },
    ],
  };

  useEffect(() => {
    slides.forEach((slide, index) => {
      if (slide.className.includes("slick-center")) {
        slide.style.opacity = 1;
        slide.style.transform = "scale(0.95)";
      } else if (slide.className.includes("slick-active slick-cloned")) {
        slide.style.opacity = 0.4;
        slide.style.transform = "scale(0.8)";
      } else {
        slide.style.opacity = 0.4;
        slide.style.transform = "scale(0.8)";
      }
    });
    duplicate.forEach((item, index) => {
      item.style.opacity = 0.4;
      item.style.transform = "scale(0.8)";
    });
  }, [currentSlide, slides]);

  return (
    <div className="testimonialContainer">
      <h1 className="headertext">Words of Vibers</h1>
      <div className="slider">
        <Slider ref={sliderRef} {...settings}>
          {employeeReview.map((item) => {
            return <TestimonialCard item={item} />;
          })}
        </Slider>
      </div>

      <div className="arrowsDiv">
        <LeftArrow onClick={handleBackward} />
        <RightArrow onClick={handleForward} />
      </div>
    </div>
  );
};

export default Testimonial;
