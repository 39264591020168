import React from "react";
import { motion, useAnimationControls } from "framer-motion";
import logImage from "./whiteLogo.png";
import spotlightImage from "./Path.svg";
import '../../../../../fonts.css'
import './styles.css'

const TopSection = () => {
  const spotlightControls = useAnimationControls();
  const backgroundControls = useAnimationControls();

  const initiateAnimation = () => {
    spotlightControls.start({
      top: 0,
      left: 0,
      scale: 4.4,
      opacity: 1,
    });
    backgroundControls.start({
      backgroundColor: "black",
    });
  };
  const endAnimation = () => {
    spotlightControls.start({
      top: "-100vh",
      left: 0,
      scale: 1,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    });
    backgroundControls.start({
      backgroundColor: "black",
    });
  };
  return (
    <motion.div
      className="topSectionContainer"
      initial={{ backgroundColor: "black" }}
      animate={backgroundControls}
    >
      <motion.img
        className="spotlightImg"
        initial={{ top: "-80vh", left: "-80vw" }}
        animate={spotlightControls}
        transition={{
          duration: 1,
        }}
        src={spotlightImage}
        alt=""
      />
      <div className="logoHeaderWrapper">
        <motion.img
          className="logImage"
          style={{
            marginTop: "-20vh",
            width: "auto",
            position: "relative",
            zIndex: 6,
          }}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1.2, opacity: 1 }}
          transition={{
            duration: 3,
          }}
          src={logImage}
          alt=""
          onViewportEnter={initiateAnimation}
          onViewportLeave={endAnimation}
        />
        <motion.h1
          className="topSectionTitle"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1.2, opacity: 1, y: 35 }}
          transition={{
            duration: 3,
          }}
        >
          World’s First Social Vibing Platform
        </motion.h1>
        <motion.p
          className="topSectionpara"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1.1, opacity: 1, y: 120 }}
          transition={{
            duration: 3,
          }}
        >
          Download it now &{" "}
          <span className="topsecParaBreak">
            <br />
          </span>
          Vibe with
          <span className="topsectionparaInside">25000+ people</span>
        </motion.p>

        <motion.div
          className="topSectionLogos"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1, y: 150 }}
          transition={{
            duration: 3,
          }}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.highOn.highOn"
            target="_blank"
            className="playstoreLogo"
          >
            <img src={require("./Asset/Playstore.png")} className="logoImg" />
          </a>
          <a
            href="https://apps.apple.com/in/app/highon/id6446462137"
            target="_blank"
          >
            <img
              src={require("./Asset/app-store-badge.png")}
              className="logoImg"
            />
          </a>
        </motion.div>
      </div>
      <div className="blurGlass" />
    </motion.div>
  );
};

export default TopSection;
