import * as React from "react";
const SVGComponent = (props) => (
  <svg
    id="Icon_ionic-ios-arrow-dropleft"
    data-name="Icon ionic-ios-arrow-dropleft"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27.955 27.955"
    {...props}
    className="arrows leftArrow"
  >
    <path
      id="Path_35"
      data-name="Path 35"
      d="M20.786,10.361a1.3,1.3,0,0,0-1.835,0L12.54,16.792a1.3,1.3,0,0,0-.04,1.788l6.317,6.337a1.295,1.295,0,1,0,1.835-1.828l-5.363-5.443,5.5-5.45A1.3,1.3,0,0,0,20.786,10.361Z"
      transform="translate(-3.764 -3.668)"
      fill="#00b7f4"
    />
    <path
      id="Path_36"
      data-name="Path 36"
      d="M17.353,3.375A13.978,13.978,0,1,0,31.33,17.353,13.975,13.975,0,0,0,17.353,3.375Zm8.36,22.337A11.822,11.822,0,1,1,8.993,8.993,11.822,11.822,0,1,1,25.712,25.712Z"
      transform="translate(-3.375 -3.375)"
      fill="#00b7f4"
    />
  </svg>
);
export default SVGComponent;
