import React, { useRef } from "react";
import { Controller, Scene } from "react-scrollmagic";
import Sequence from "./Sequence";
import "./VibeWithUsSequencer.css";

const VibeWithUsSequencer = () => {
  const ref = useRef();
  return (
    <div className="vibeWithUsAnimation">
      <Controller>
        <Scene duration="30%" triggerHook="onCenter">
          {(progress) => (
            <div
              className="vibeWithUsSequencer"
              style={{
                height: "85vh",
                width: "100vw",
                position: "relative",
              }}
            >
              <Sequence ref={ref} progress={progress} vibeWithus={true} />
            </div>
          )}
        </Scene>
      </Controller>
    </div>
  );
};

export default VibeWithUsSequencer;
