import React from "react";
import ArrowAnim from "./Asset/corousel_images/arrowAnim.json";
import Lottie from "react-lottie";

import img1 from "./Asset/corousel_images/1.webp";
import img2 from "./Asset/corousel_images/2.webp";
import img3 from "./Asset/corousel_images/3.webp";
import img4 from "./Asset/corousel_images/4.webp";
import img5 from "./Asset/corousel_images/5.webp";
import img6 from "./Asset/corousel_images/6.webp";
import img7 from "./Asset/corousel_images/7.webp";
import img8 from "./Asset/corousel_images/8.webp";
import img9 from "./Asset/corousel_images/9.webp";
import img10 from "./Asset/corousel_images/10.webp";
import img11 from "./Asset/corousel_images/11.webp";
import img12 from "./Asset/corousel_images/12.webp";
import img13 from "./Asset/corousel_images/13.webp";
import img14 from "./Asset/corousel_images/14.webp";
import img15 from "./Asset/corousel_images/15.webp";

import "./styles.css";

const AnimatedCorousel = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: ArrowAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Lottie
        options={defaultOptions}
        height={"4vh"}
        width={"4vw"}
        // putting this bcz by id and classname it is not working
        style={{
          zIndex: "4",
          transform: "rotate(270deg)",
          position: "relative",
          right: "-45vw",
          top: "3vh",
        }}
      />
      <div className="carouselWrapper">
        <div className="corouselParentDiv corouselContainer">
          <div className="corousel-div-1">
            <div className="carouselCard corousel-text corouselCategory1 text1">
              Post your <br /> videos and photos
              <br /> to show your vibe
            </div>

            <div className="carouselCard corouselCategory2">
              <img className="carouselImg" src={img1} alt="img1" />
            </div>

            <div className="carouselCard corouselCategory1">
              <img className="carouselImg" src={img3} alt="img1" />
            </div>

            <div className="carouselCard corouselCategory1">
              <img className="carouselImg" src={img15} alt="img1" />
            </div>

            <div className="carouselCard corouselCategory3">
              <img className="carouselImg" src={img6} alt="img1" />
            </div>

            <div className="carouselCard corouselCategory1">
              <img className="carouselImg" src={img12} alt="img1" />
            </div>

            <div className="carouselCard corouselCategory4">
              <img className="carouselImg" src={img2} alt="img1" />
            </div>

            <div className="carouselCard corouselCategory3">
              <img className="carouselImg" src={img4} alt="img1" />
            </div>

            <div className="carouselCard corouselCategory1">
              <img className="carouselImg" src={img5} alt="img1" />
            </div>

            <div className="carouselCard corouselCategory5">
              <img className="carouselImg" src={img11} alt="img1" />
            </div>
          </div>

          <div className="corousel-div-2">
            <div className="carouselCard corouselCategory6">
              <img className="carouselImg" src={img9} alt="img1" />
            </div>

            <div className="carouselCard corousel-text corouselCategory9 text2">
              all new feed <br />
              viewing experience
            </div>

            <div className="carouselCard corouselCategory7">
              <img className="carouselImg" src={img13} alt="img1" />
            </div>

            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginRight: "15px",
              }}
              className="corouselCategory10"
            >
              <div
                style={{
                  width: "100%",
                  height: "55%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="carouselCard" style={{ width: "40%" }}>
                  <img className="carouselImg" src={img7} alt="img1" />
                </div>

                <div
                  className="carouselCard corousel-text text3"
                  style={{
                    width: "60%",
                  }}
                >
                  Share the <br />
                  vibe all across
                </div>
              </div>
              <div
                style={{
                  height: "40%",
                }}
              >
                <img className="carouselImg" src={img10} alt="img1" />
              </div>
            </div>

            <div className="carouselCard corouselCategory7">
              <img className="carouselImg" src={img14} alt="img1" />
            </div>

            <div className="carouselCard corouselCategory11">
              <img className="carouselImg" src={img8} alt="img1" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimatedCorousel;
