import { useRef, useState } from "react";
import "./question.css";
const Question = ({ item }) => {
  const [isActive, setIsActive] = useState(false);
  const answerRef = useRef(null);
  const textRef = useRef(null);

  const toggleView = () => {
    if (answerRef.current.className.includes("show")) {
      setIsActive(!isActive);
      answerRef.current.style.height = "0px";
      answerRef.current.style.opacity = 0;
    } else {
      setIsActive(!isActive);
      answerRef.current.style.height = textRef.current.offsetHeight + "px";
      answerRef.current.style.opacity = 1;
    }
  };

  return (
    <div class="questionItem">
      <div className="questionHeader" onClick={() => {
            toggleView();
          }}>
        <h2>{item.question}</h2>
        <button
          className={isActive ? "expandButton active" : "expandButton"}
          onClick={() => {
            toggleView();
          }}
        >
          <span class={isActive ? "active" : ""}></span>
        </button>
      </div>
      <div
        id="answerContent"
        className={isActive ? "show" : ""}
        ref={answerRef}
      >
        <p ref={textRef} dangerouslySetInnerHTML={{__html: item.answer}}/>
      </div>
      <div className={isActive ? "fadedBorder2" : "fadedBorder1"}></div>
    </div>
  );
};
export default Question;
