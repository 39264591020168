import React from "react";
import "./testimonial.css";
import "./card.scss";
import UpComma from "../Asset/upComma";
import DownComma from "../Asset/downComma";

const TestimonialCard = ({ item }) => {
  return (
    <div className="testocardContainer">
      <div className="testoimgContainer">
        <img id="testoimg" src={item.img} alt="" />
      </div>
      <div className="testocontentContainer">
        <div className="paraDiv">
          <DownComma className="downComma" />
          <p className="contentPara">{item.review}</p>
          <UpComma className="upComma" />
        </div>
        <p className="bottomtext">
          - {item.name}. <span className="testoLocation">{item.location}</span>
        </p>
      </div>
    </div>
  );
};

export default TestimonialCard;
