import React from 'react';
import "./styles.css";

const TermsAndCondition = () => {
    return (
        <div style={{ margin: "50px 15px" }}>
        <p className="termsHeading">1.1 WELCOME TO HIGHON:</p>
        <p className="termsHeading termsContent">
          We are here to guide you with all the terms of usage, policy of Data
          and cookies which remain intact while the usage of application. Highon
          is one of the services being offered to its customers from the
          platform of the TechArdor global Pvt Ltd. The agenda of TechArdor
          global Pvt Ltd in introducing Highon Product as application is to make
          people closer to each other and create communities of similar mindsets
          which help them in have a better social life. By creating Highon
          account through a mobile device you agree to be bound by this below
          mention terms of use. In case, if you do not accept or agree to be
          bound by all the terms of usage, please refrain from using the
          service. Highon will have complete authority to make changes to terms
          of usage, policy of Data and cookies at any point of time depending
          upon the requirements of law, due to new features or change in
          business practices. The latest version of agreement and terms of use
          can be found under Terms & Policy of settings tab.
        </p>

        <p className="termsHeading">1.2 WHO CAN USE HIGHON:</p>
        <p className="termsHeading termsContent">
          As Highon targets to maximise its reach towards the customers to
          enable them to have a better social life by finding new communities
          and people to vibe with. We also target to make it a safe and secured
          place for every customer out there. So, to achieve this greater
          commitment towards building a safe and secure environment we need your
          support and complete agreement in adhering the below mentioned terms
          of usage.{"\n"} - You must be at least 18 years old or the minimum
          legal age in your country to use Highon. {"\n"}- You should not be
          convicted sex offender. BY ACCESSING OR USING THE SERVICES, YOU
          REPRESENT AND WARRANT THAT YOU ARE AT LEAST 18 YEARS OF AGE.
        </p>

        <p className="termsHeading">1.3 WHAT NOT TO DO:</p>
        <p className="termsHeading termsContent">
          You cannot pretend to be someone else or give false information.
          Highon does not require that you reveal your identity, but you are
          required to give us current and accurate name, which may entail giving
          personal information. You may not make an account for someone else
          unless you have that person's specific consent. You may not
          impersonate someone or something you are not.{"\n"}You cannot do
          anything unlawful, misleading, or fraudulent or for an illegal or
          unauthorized purpose. You cannot do anything to interfere with or
          impair the intended operation of the Service. This includes misusing
          any reporting, dispute, or appeals channel, such as by making
          fraudulent or groundless reports or appeals.{"\n"}You are not
          permitted to make an attempt to establish accounts, gain unauthorised
          access, or gather information. This includes making accounts or
          gathering data automatically without getting our prior consent. Any
          accounts or data you gain from us or our service are not eligible for
          sale, licencing, or purchase. This includes attempting to purchase,
          sell, or transfer any component of your account, including your
          username; soliciting, obtaining, or using another user's login
          information or badge; or requesting or obtaining another user's Highon
          username, password, or unauthorised access token. You cannot post
          another person's private or sensitive and confidential material
          without their consent, nor may you engage in any other behaviour that
          infringes on their rights, including their intellectual property
          rights (e.g., copyright infringement, trademark infringement,
          counterfeit, or pirated goods). According to any relevant exceptions
          or restrictions to copyright and associated rights, you may use
          someone else's works. You warrant that the material you upload or
          share is yours or that all relevant rights have been secured.{"\n"}You
          cannot modify, translate, create derivative works of, or reverse
          engineer our products or their components. Without our previous
          written approval, you are not permitted to include a domain name or
          URL in your username.{"\n"}Subject to your compliance with these Terms
          and all other applicable Rules including but not limited to the
          Community Guidelines, you are granted a limited, non-exclusive,
          non-sublicensable, revocable, non-transferable license to access and
          use the Services. No licenses or rights are granted to you by
          implication or otherwise under any intellectual property rights owned
          or controlled by Highon or its licensors, except for licenses and
          rights expressly granted in these Terms.{"\n"}The Services provide
          communication channels designed to enable users to communicate with
          other users. Highon does not exert any control over the individuals
          you interact with, even if you select the secret chat option, which
          Highon may offer. Highon has no obligation to monitor these
          communication channels but may, in its discretion, do so in connection
          with providing the Services. Highon may also terminate, suspend, or
          ban your access to and use of the Services at any time, without
          notice, for any reason in its sole discretion. You acknowledge and
          agree that any user content, including without limitation text chats,
          is not created, endorsed, or controlled by Highon.{"\n"}Highon will
          not under any circumstances be liable for any user content or activity
          within the Services. Highon is not responsible for information or
          content that you choose to share within or through the Services nor is
          Highon responsible for the content or actions of other users of the
          Services. Highon is not responsible for maintaining copies of any
          information or communications you choose to submit to or through the
          Services.{"\n"}You are solely responsible for your interaction with
          other users of the Services and other parties that you come in contact
          with through the Services. To the fullest extent permitted by
          applicable law, Highon hereby disclaims any and all liability to you
          or any third party relating to your use of the Services. You
          acknowledge and agree that Highon does not have any special
          relationship with you as an end user, and as such, Highon does not owe
          you any duty to protect you from the acts of other users or other
          third parties.{"\n"}Neither the above restrictions, nor the Community
          Guidelines, the Rules, or anything else in the Terms, shall be
          construed to create any rights enforceable by users, whether as
          third-party beneficiaries or otherwise. Highon has the right, but not
          the obligation, to enforce any of the foregoing.
        </p>

        <p className="termsHeading">Summary of what not to do:</p>
        <p className="termsHeading termsContent">
          You are solely responsible for compliance with any and all laws,
          rules, and regulations that may apply to your use of the Services. You
          agree that you will comply with these Terms and the Community
          Guidelines and will not, and will not assist or enable others to:{" "}
          {"\n"}- breach or circumvent any applicable laws or regulations,
          agreements with third parties, third-party rights, or our Terms or
          Rules; {"\n"}- use the Services for any other purposes that are not
          expressly permitted by these Terms or in a manner that falsely implies
          Highon’s endorsement, partnership or otherwise misleads others as to
          your affiliation with Highon; {"\n"}- license, sell, transfer, assign,
          distribute, host, or otherwise commercially exploit the Services;{" "}
          {"\n"}- except as explicitly stated herein, copy, reproduce,
          distribute, republish, download, display, post or transmit the
          Services, in whole or in part, in any form or by any means; {"\n"}-
          use, display, mirror or frame the Services or any individual element
          within the Services, the Highon name, any Highon trademark, logo or
          other proprietary information, or the layout and design of any page or
          form contained on a page in the Services, without Highon express
          written consent; {"\n"}- use any robots, spider, crawler, scraper or
          other automated means or processes to access, collect data or other
          content from or otherwise interact with the Services for any purpose;{" "}
          {"\n"}- avoid, bypass, remove, deactivate, impair, descramble, or
          otherwise attempt to circumvent any technological measure implemented
          by Highon or any of Highon’s providers to protect the Services; {"\n"}
          - modify, make derivative works of, attempt to decipher, decompile,
          disassemble, or reverse engineer any of the software used to provide
          the Services; {"\n"}- take any action that damages or adversely
          affects, or could damage or adversely affect the performance or proper
          functioning of the Services; or {"\n"}- violate or infringe anyone
          else’s rights or otherwise cause or threaten harm to anyone.
        </p>

        <p className="termsHeading">1.4 ASSUMPTION OF RISK:</p>
        <p className="termsHeading termsContent">
          You acknowledge and agree that use of the Services, including your
          interactions with other users, may carry inherent risk and by
          accessing and using the Services, you choose to assume those risks
          voluntarily. To the fullest extent permitted by applicable law, you
          assume full responsibility for your use of the Services, including
          your interactions with other users. {"\n"}TO THE FULLEST EXTENT
          PERMITTED UNDER APPLICABLE LAW, YOU KNOWINGLY, VOLUNTARILY AND FREELY
          ASSUME ALL RISKS, BOTH KNOWN AND UNKNOWN, OF ACCESSING OR USING THE
          SERVICES, EVEN IF THOSE RISKS ARISE FROM THE NEGLIGENCE OR
          CARELESSNESS OF HIGHON, THIRD-PARTIES INCLUDING OTHER USERS OF THE
          SERVICES, OR DEFECTS IN THE SERVICES.
        </p>

        <p className="termsHeading">1.5 NO WARRANTIES:</p>
        <p className="termsHeading termsContent">
          TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, HIGHON PROVIDES
          THE SERVICES ON AN “AS IS” AND “AS AVAILABLE” AND “WITH ALL FAULTS”
          BASIS, WITHOUT WARRANTY OF ANY KIND. TO THE FULLEST EXTENT PERMISSIBLE
          UNDER APPLICABLE LAW, HIGHON AND ITS AFFILIATES AND LICENSORS DISCLAIM
          ALL WARRANTIES AND CONDITIONS OF ANY KIND, EXPRESS OR IMPLIED,
          INCLUDING BUT NOT LIMITED TO, WARRANTIES OF TITLE, IMPLIED WARRANTIES
          OF NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
          (EVEN IF HIGHON IS ADVISED OF SUCH PURPOSE), AND IMPLIED WARRANTIES
          ARISING FROM A PARTICULAR COURSE OF DEALING OR USAGE OF TRADE. WITHOUT
          LIMITING THE FOREGOING, NEITHER HIGHON NOR ANY OF ITS AFFILIATES OR
          LICENSORS, NOR ANY OF ITS OR THEIR OFFICERS, DIRECTORS, LICENSORS,
          EMPLOYEES OR REPRESENTATIVES REPRESENT OR WARRANT (I) THAT THE
          SERVICES WILL MEET YOUR REQUIREMENTS OR BE ACCURATE, TRUTHFUL,
          COMPLETE, RELIABLE, OR ERROR FREE, (II) THAT THE SERVICES WILL ALWAYS
          BE AVAILABLE OR WILL BE UNINTERRUPTED, ACCESSIBLE, TIMELY, RESPONSIVE,
          OR SECURE, (III) THAT ANY ERRORS OR DEFECTS WILL BE CORRECTED, OR THAT
          THE SERVICES WILL BE FREE FROM VIRUSES, WORMS, TROJAN HORSES OR OTHER
          HARMFUL PROPERTIES, (IV) THE ACCURACY, RELIABILITY, TIMELINESS OR
          COMPLETENESS OF ANY CONTENT AVAILABLE ON OR THROUGH THE SERVICES, (V)
          ANY IMPLIED WARRANTY ARISING FROM COURSE OF DEALING OR USAGE OF TRADE,
          OR (VI) THAT ANY CONTENT PROVIDED VIA THE SERVICES IS NON-INFRINGING.
          NO INFORMATION OR ADVICE PROVIDED THROUGH THE SERVICES BY HIGHON OR BY
          HIGHON’S EMPLOYEES OR AGENTS SHALL CREATE ANY WARRANTY. {"\n"}Some
          jurisdictions do not allow the exclusion of certain warranties, so
          some of the above limitations and exclusions may not apply to you.
        </p>

        <p className="termsHeading">Other Users of the Services. </p>
        <p className="termsHeading termsContent">
          HIGHON HAS NO CONTROL OVER AND DOES NOT MAKE, AND HEREBY EXPRESSLY
          DISCLAIMS, ANY REPRESENTATIONS, WARRANTIES OR GUARANTEES AS TO THE
          CONDUCT, ACTS OR OMISSIONS OF OTHER USERS OF THE SERVICES. YOU
          ACKNOWLEDGE AND AGREE THAT YOU SHALL LOOK SOLELY TO THE OTHER USERS,
          AND NOT HIGHON, WITH RESPECT TO ANY CLAIMS OR CAUSES OF ACTION ARISING
          FROM OR RELATING TO THE ACTIONS OR CONDUCT OF OTHER USERS OF THE
          SERVICES. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER
          NO CIRCUMSTANCES SHALL HIGHON BE RESPONSIBLE FOR ANY LOSS, DAMAGE OR
          INJURY RESULTING FROM ANY ACTION, CONDUCT OR OMISSION OF ANY OTHER
          USER OF THE SERVICES.
        </p>

        <p className="termsHeading">1.6 LIMITATION OF LIABILITY:</p>
        <p className="termsHeading termsContent">
          Limitations on Highon’s Liability: YOU ACKNOWLEDGE AND AGREE THAT, TO
          THE FULLEST EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF
          YOUR ACCESS TO AND USE OF THE SERVICES REMAINS WITH YOU. NEITHER
          HIGHON NOR ANY OTHER PARTY INVOLVED IN CREATING, PRODUCING, OR
          DELIVERING THE SERVICES WILL BE LIABLE TO YOU OR ANY THIRD PARTY FOR
          ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR
          CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF
          GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE OR
          THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, OR FOR ANY DAMAGES FOR
          PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN
          CONNECTION WITH (I) THESE TERMS, (II) THE USE OF THE SERVICES,
          INCLUDING BUT NOT LIMITED TO ANY DAMAGE CAUSED BY ANY RELIANCE ON, OR
          ANY DELAYS, INACCURACIES, ERRORS OR OMISSIONS IN, THE SERVICES,
          WHETHER PROVIDED BY HIGHON OR BY THIRD PARTIES, (III) THE USE OF OR
          INABILITY TO USE THE SERVICES FOR ANY REASON, OR (IV) YOUR
          COMMUNICATIONS, INTERACTIONS OR DEALINGS WITH, OR THE CONDUCT OF,
          OTHER USERS OF THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT
          (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY,
          AND WHETHER OR NOT HIGHON HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH
          DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE
          FAILED OF ITS ESSENTIAL PURPOSE. IN NO EVENT WILL HIGHON’S AGGREGATE
          LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR YOUR USE
          OF OR INABILITY TO USE THE SERVICES (INCLUDING BUT NOT LIMITED TO YOUR
          INTERACTIONS WITH OTHER USERS OF THE SERVICES) EXCEED ONE THOUSAND
          INDIAN RUPEES (INR1000.00). {"\n"}The limitations of damages set forth
          above are fundamental elements of the basis of the bargain between
          Highon and you. Some jurisdictions do not allow the exclusion or
          limitation of liability for consequential or incidental damages, so
          some of the above limitations and exclusions may not apply to you. No
          Liability for Non-Highon Actions. {"\n"}TO THE FULLEST EXTENT
          PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL HIGHON BE LIABLE FOR ANY
          DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL,
          COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING OUT OF OR
          RELATING TO THE CONDUCT, ACTS OR OMISSIONS OF YOU OR ANY OTHER THIRD
          PARTY, INCLUDING OTHER USERS OF THE SERVICES, IN CONNECTION WITH THE
          USE OF THE SERVICES, INCLUDING WITHOUT LIMITATION, BODILY INJURY,
          EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES. Some jurisdictions do
          not allow the exclusion or limitation of liability for consequential
          or incidental damages, so some of the above limitations and exclusions
          may not apply to you.
        </p>

        <p className="termsHeading">1.8 THIRD-PARTY LINKS AND SERVICES:</p>
        <p className="termsHeading termsContent">
          The Services may contain links to other websites, businesses,
          resources and advertisers, and other sites may link to the Services.
          Clicking on a link will redirect you away from the Services to a
          third-party site or service. Highon is not responsible for examining
          or evaluating, and does not warrant the goods, services, or offerings
          of any third party or the content of their websites or advertisements.
          Consequently, Highon does not assume any liability or responsibility
          for the accuracy, actions, products, services, practices,
          availability, or content of such third parties. You should direct any
          concerns regarding other sites and services to their operators.
        </p>

        <p className="termsHeading">
          1.9 AUTHORIZATIONS YOU PROVIDE TO HIGHON:
        </p>
        <p className="termsHeading">
          As per the agreement with Highon, user need to provide below mentioned
          permissions to avail the service.{" "}
        </p>
        <p className="termsHeading termsContent">
          You give us permission to use your work, but we do not claim ownership
          of it. Your rights to your content remain unaffected. You are free to
          share your content with anyone you like, wherever you like, and we
          make no claim to ownership of the content you publish on or via the
          Service. To deliver the Service, however, we must get specific legal
          authorizations from you (referred to as a "licencing"). You hereby
          grant to us a non-exclusive, royalty-free, transferable,
          sub-licensable, worldwide licence to host, use, distribute, modify,
          run, copy, publicly perform or display, translate, and create
          derivative works from any intellectual property rights in any content
          you share, post, or upload to our Service or in connection with it
          (consistent with your privacy and application settings). When your
          work is removed from our systems, this licence expires. By cancelling
          your account, you may erase material one item at a time or all of it.
        </p>
        <p className="termsHeading">
          Permission to utilise your username, profile photo, and data regarding
          your interactions and behaviour with other users, advertisements, and
          sponsored content.
        </p>
        <p className="termsHeading termsContent">
          You agree that we may display your username, profile picture, and
          details about your interactions with accounts, ads, offers, and other
          sponsored material that you follow or vibe with on Highon. We can use
          this information across the platform without any compensation benefits
          to the users. In case of any sponsored medium require data of user
          specific follows or the data regarding regular usage of app and the
          general pattern of likes or dislikes can be shared without any
          compensation benefits to the user. The point to note in terms of data
          sharing will always keep the username or the user details
          covered/incognito. Highon always respects your personal choice in
          showcasing the advertising content which can be monitored or altered
          thorough ad settings under personal profile settings.
        </p>
        <p className="termsHeading">
          Permission to perform the activity of downloading and installing
          Highon Service updates on your device.
        </p>
        <p className="termsHeading termsContent">
          Highon always acquire the right to disqualify or modify the user
          selected user ID, if in case it is inappropriate or need necessary
          actions because Highon believes that it affects someone’s intellectual
          property or been claimed by the any other user showing the proper
          documentation of the authority. We maintain full ownership of all
          intellectual property rights in the pictures, graphics, videos, and
          sounds that you use in your creations or sharing of anything that we
          make available through our Service (but not yours). You can only use
          our intellectual property and trademarks with the permission from
          Highon. To change, make derivative works from, decompile, or make any
          other attempt to extract our source code, you must first have written
          consent from us or be operating under an open-source licence.
        </p>

        <p className="termsHeading">1.10 REMOVAL OF THE CONTENT:</p>
        <p className="termsHeading termsContent">
          In case of any observed misuse or sexual content or communal hatred or
          personal aggression, Highon will be complete and final authority to
          take a decision on removing the content. In case of complaint raised
          by any user among the community of Highon on the elements of social
          bullying or usage of intellectual property or misusing the platform by
          publishing sexual nudity or social abuse or in case of encouraging
          illegal activities like threatening or blackmailing the concerned post
          or content will be deleted from the platform without any prior
          information to the concerned user. In case of any damage occurred by
          the post/content before/during the process of the removal of the
          content will also be analysed and necessary actions will indulge not
          only removal of post/content but will also end up in permanent
          deletion of the account or disqualify the individual profile/creator’s
          profile from Highon. Highon is not liable for any damage occurred by
          the post/content before/during the process of the identification and
          removal. Highon will take at lease of 3 days to retrieve back on the
          actions in case of compliant raised by the users.
        </p>

        <p className="termsHeading">1.11 DELETION OF PROFILE:</p>
        <p className="termsHeading termsContent">
          In case of any observed misuse or sexual content or communal hatred or
          personal aggression, Highon will be complete and final authority to
          take a decision on deleting the account (individual’s account or
          creators account). In case of complaint raised by any user among the
          community of Highon on the elements of social bullying or usage of
          intellectual property or misusing the platform by publishing sexual
          nudity or social abuse or in case of encouraging illegal activities
          like threatening or blackmailing the concerned user account will be
          deleted from the platform without any prior information to the
          concerned user. In case of any damage occurred by the user account
          before/during the process of the removal of the content will also be
          analysed and necessary actions will indulge not only deletion of
          account but will also end up in disqualifying the individual
          profile/creator’s profile. The concerned owner of the individual
          profile/creator’s profile can raise the request to regain access to
          the account within 7 days. Once the proposal for regaining is
          initiated Highon will take at least 15 working days to investigate the
          purpose and close the issue.
        </p>

        <p className="termsHeading">1.12 DISQUALIFYING OF PROFILE:</p>
        <p className="termsHeading termsContent">
          In case of any observed misuse or sexual content or communal hatred or
          personal aggression, Highon will be complete and final authority to
          take a decision on disqualifying the account (individual’s account or
          creators account). In case of complaint raised by any user among the
          community of Highon on the elements of social bullying or usage of
          intellectual property or misusing the platform by publishing sexual
          nudity or social abuse or in case of encouraging illegal activities
          like threatening or blackmailing the concerned user account will be
          disqualified from the platform without any prior information to the
          concerned user. Disqualification of profile defines that no further
          account creation is possible by using blacklisted phone number and
          email id. In case of any damage occurred from the user account
          before/during the process of the removal of the content will also be
          analysed and necessary actions will indulge in disqualifying the
          account and perhaps subjecting the person in charge of it to legal
          action. The concerned owner of the individual profile/creator’s
          profile can raise the request to regain access to the account with-in
          7 days. Once the proposal for regaining is initiated Highon will take
          at least 15 working days to investigate the purpose and close the
          issue. By becoming a Highon user, you have given Highon your consent &
          complete authority to removal/delete/disqualify any content/account
          which violates Highon’s terms and conditions. These Terms will end as
          an agreement between you and us if you delete or we disable your
          account.
        </p>

        <p className="termsHeading">1.13 ASSIGNMENT:</p>
        <p className="termsHeading termsContent">
          You may not assign, transfer, or delegate this Agreement and your
          rights and obligations hereunder without Highon’s prior written
          consent. Highon may, without restriction, assign, transfer or delegate
          this Agreement and any rights and obligations hereunder, at its sole
          discretion.
        </p>

        <p className="termsHeading">1.14 NO WAIVER AND SEVERABILITY:</p>
        <p className="termsHeading termsContent">
          Highon’s failure to enforce a provision of this Agreement is not a
          waiver of its right to do so later or to enforce any other provision.
          Except as expressly set forth in this Agreement, the exercise by
          either party of any of its remedies under this Agreement will be
          without prejudice to its other remedies under this Agreement or
          otherwise permitted under law. Except as explicitly provided herein,
          if any provision of this Agreement is held to be unenforceable for any
          reason, such provision will be reformed only to the extent necessary
          to make it enforceable, and such decision will not affect the
          enforceability of such provision under other circumstances, or of the
          remaining provisions hereof under all circumstances.
        </p>

        <p className="termsHeading">
          1.15 CHANGES TO THE SERVICES OR THE TERMS:
        </p>
        <p className="termsHeading termsContent">
          Highon reserves the right, at any time and in our sole discretion, to
          amend, modify, suspend, or terminate, temporarily or permanently, the
          Services, and any part thereof, without notice to you. Highon shall
          have no liability to you or any other person or entity for any
          modification, suspension, or termination of the Services or any part
          thereof. Highon reserves the right to modify these Terms (effective on
          a prospective basis) at any time in accordance with this provision.
          Therefore, you should review these Terms regularly. If we make changes
          to these Terms, we will post the revised Terms on the Services and
          update the “Last Updated” date at the top of these Terms. If you do
          not terminate this Agreement before the date the revised Terms become
          effective, your continued access to or use of the Services will
          constitute acceptance of the revised Terms. Special terms or rules may
          apply to some Services. Any such terms are in addition to these Terms.
          In the event of any conflict or inconsistency between these Terms, our
          Privacy Notice, and any rules, restrictions, limitations, terms and/or
          conditions that may be communicated to users of the Services, Highon
          shall determine which rules, restrictions, limitations, terms and/or
          conditions shall control and prevail, in our sole discretion, and you
          specifically waive any right to challenge or dispute such
          determination.
        </p>

        <p className="termsHeading">1.16 ENTIRE AGREEMENT:</p>
        <p className="termsHeading termsContent">
          Except as it may be supplemented by additional terms and conditions,
          policies, guidelines, or standards as provided herein, this Agreement
          constitutes the entire agreement between Highon and you pertaining to
          the subject matter hereof, and supersedes any and all prior oral or
          written understandings or agreements between Highon and you in
          relation to the access to and use of the Services.
        </p>

        <p className="termsHeading">
          1.17 TERM, TERMINATION, AND SURVIVAL:
        </p>
        <p className="termsHeading termsContent">
          This Agreement will remain in full force and effect while you use the
          Services in accordance with these Terms and any additional applicable
          Rules. Highon may terminate this Agreement at any time without notice
          if we believe that you have breached this Agreement or the Community
          Guidelines, including but not limited to, by using the Services for
          non-personal use, engaging in prohibited activities, and any breach of
          your representations and warranties. All provisions of this Agreement
          which by their nature should survive termination shall survive
          termination, including without limitation, ownership provisions,
          warranty disclaimers, assumption of risk agreement, release of claims,
          indemnity, limitations of liability, and dispute resolution.
        </p>

        <p className="termsHeading">1.18 FEEDBACK:</p>
        <p className="termsHeading termsContent">
          We welcome and encourage you to provide feedback, comments, and
          suggestions for improvements to the Services (collectively,
          “Feedback”). You may submit Feedback by emailing us at
          support@highon.co.in with “Highon-Feedback” in the subject line. Any
          Feedback you submit to us will be considered non-confidential and
          non-proprietary to you. By submitting Feedback to us, you grant us a
          non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable,
          perpetual license to use and publish those ideas and materials for any
          purpose, without compensation to you. Highon always value your
          feedback and any recommendations, we are under no obligation to
          utilise them and we are not obligated to pay you for them if we did or
          keep them a secret.
        </p>

        <p className="termsHeading">1.19 THE TERMS OF DISAGREEMENT:</p>
        <p className="termsHeading termsContent">
          In case of any exemptions from the above-mentioned terms and
          conditions, Highon should give its agreement through a written
          approval. In case of any point in the agreement is unenforceable the
          remaining terms and conditions remain in effect. No third party is
          involved in agreement. User can never transfer their agreement of
          alignment with any individual/organization without Highon’s written
          consent. We can delegate our duties and rights to others. This might
          happen, for instance, if our ownership changes (as in a merger,
          acquisition, or sale of assets) or due to a legal need.
        </p>

        <p className="termsHeading">1.20 HANDLING DISPUTES:</p>
        <p className="termsHeading termsContent">
          If a claim or dispute arises out of or relates to your use of the
          Service as a consumer, both you and us agree that you may resolve your
          individual claim or dispute against us, and we may resolve our claim
          or dispute against you under the jurisdiction of the courts of
          Hyderabad, Telangana. All other claims and disputes are also resolved
          under the jurisdiction of the courts of Hyderabad, Telangana.
        </p>
      </div>
    )
}

export default TermsAndCondition;
