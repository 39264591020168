import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "../../../../../../fonts.css";

import "./badgeSection.styles.scss";

const BadgesSection = () => {
  const dur = 0.7;

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => {
        setMatches(media.matches);
      };
      media.addListener(listener);
      return () => media.removeListener(listener);
    }, [matches, query]);

    return matches;
  };

  const useIsLarge = () => useMediaQuery("(min-width: 1700px)");
  const useIsMedium = () => useMediaQuery("(min-width: 1100px)");
  const useIsSmall = () => useMediaQuery("(min-width: 700px)");
  const useIsMobileSmall = () => useMediaQuery("(min-width: 500px)");
  const isLarge = useIsLarge();
  const isMedium = useIsMedium();
  const isSmall = useIsSmall();
  const isMobileSmall = useIsMobileSmall();

  const centerCircleCss = isLarge
    ? {
        height: "230px",
        width: "230px",
        borderRadius: "315px",
      }
    : isMedium
    ? {
        height: "160px",
        width: "160px",
        borderRadius: "215px",
      }
    : isSmall
    ? {
        height: "140px",
        width: "140px",
        borderRadius: "215px",
      }
    : isMobileSmall
    ? {
        height: "100px",
        width: "100px",
        borderRadius: "215px",
      }
    : {
        height: "80px",
        width: "80px",
        borderRadius: "215px",
      };

  const oneCircleCss = isLarge
    ? {
        left: "0%",
        height: "180px",
        width: "180px",
      }
    : isMedium
    ? {
        left: "0%",
        width: "130px",
        height: "130px",
      }
    : isSmall
    ? {
        left: "0%",
        width: "110px",
        height: "110px",
      }
    : isMobileSmall
    ? {
        left: "0%",
        width: "80px",
        height: "80px",
      }
    : {
        left: "0%",
        width: "55px",
        height: "55px",
      };
  const twoCircleCss = isLarge
    ? {
        left: "0%",
        height: "160px",
        width: "160px",
      }
    : isMedium
    ? {
        left: "0%",
        height: "100px",
        width: "100px",
      }
    : isSmall
    ? {
        left: "0%",
        height: "80px",
        width: "80px",
      }
    : isMobileSmall
    ? {
        left: "0%",
        height: "70px",
        width: "70px",
      }
    : {
        left: "0%",
        height: "45px",
        width: "45px",
      };
  const threeCircleCss = isLarge
    ? {
        left: "0%",
        width: "140px",
        height: "140px",
      }
    : isMedium
    ? {
        left: "0%",
        height: "80px",
        width: "80px",
      }
    : isSmall
    ? {
        left: "0%",
        height: "60px",
        width: "60px",
      }
    : isMobileSmall
    ? {
        left: "0%",
        height: "50px",
        width: "50px",
      }
    : {
        left: "0%",
        height: "30px",
        width: "30px",
      };

  const badgeContentCss = !isLarge
    ? {
        opacity: 1,
        top: "64%",
      }
    : {
        opacity: 1,
        top: "64%",
      };
  const centerCircleParaCss = !isLarge
    ? {
        y: "-66px",
      }
    : {
        y: "-66px",
      };

  return (
    <div className="parent-badges-container">
      <motion.div className="circle-container">
        <motion.div
          className="side-circles circle-three-left"
          initial={{
            left: "40%",
            height: "5vh",
            width: "3%",
          }}
          whileInView={threeCircleCss}
          transition={{
            duration: dur,
            ease: "easeInOut",
          }}
        />

        <motion.div
          className="side-circles circle-two-left"
          initial={{
            left: "30%",
            height: "6vh",
            width: "3.5%",
          }}
          whileInView={twoCircleCss}
          transition={{
            duration: dur,
            ease: "easeInOut",
          }}
        />

        <motion.div
          className="side-circles circle-one-left"
          initial={{
            height: "6.5vh",
            width: "4%",
            left: "20%",
          }}
          whileInView={oneCircleCss}
          transition={{
            duration: dur,
            ease: "easeInOut",
          }}
        />

        <motion.div
          className="center-circle"
          initial={{
            // width: '19%',
            // height: '13%',
            backgroundColor: "#ed752f",
            borderRadius: "265px",
          }}
          whileInView={centerCircleCss}
          transition={{
            duration: dur,
            ease: "easeInOut",
          }}
        >
          <motion.p
            initial={{
              y: "-30px",
            }}
            whileInView={centerCircleParaCss}
            transition={{
              duration: dur,
            }}
            className="badgeText"
          >
            Badges
          </motion.p>
        </motion.div>

        <motion.div
          className="side-circles circle-one-right"
          initial={{
            left: "-20%",
            height: "6.5vh",
            width: "4%",
          }}
          whileInView={oneCircleCss}
          transition={{
            duration: dur,
            ease: "easeInOut",
          }}
        />
        <motion.div
          className="side-circles circle-two-right"
          initial={{
            height: "6vh",
            width: "3.5%",
            left: "-30%",
          }}
          whileInView={twoCircleCss}
          transition={{
            duration: dur,
            ease: "easeInOut",
          }}
        ></motion.div>
        <motion.div
          className="side-circles circle-three-right"
          initial={{
            left: "-40%",
            height: "5vh",
            width: "3%",
          }}
          whileInView={threeCircleCss}
          transition={{
            duration: dur,
            ease: "easeInOut",
          }}
        />
      </motion.div>

      <motion.div
        className="badge-content-container"
        initial={{
          top: "57%",
          opacity: 0,
        }}
        whileInView={badgeContentCss}
        transition={{
          duration: dur,
        }}
      >
        This feature helps your followers and vibers understand your evolution
        levels on Highon. Your badge will level up after critiquing the time you
        spent on the app, your activities, your number of followers, and your
        vibes
      </motion.div>

      {/* <motion.div className="explore-more-container"
                onHoverStart={() => setIsExploreMore(true)} onHoverEnd={() => setIsExploreMore(false)}
                initial={{
                    opacity: 0.2,
                    width: '3.5%',
                    height: '6.5%',
                    borderRadius: '50%',
                }}
                whileInView={{ opacity: 1 }}
                whileHover={{
                    width: '9%',
                    height: '6vh',
                    borderRadius: '34px',
                }}
                transition={{
                    duration: 0.3,
                }}
            >
                <motion.p onHoverStart={() => setIsExploreMore(true)} onHoverEnd={() => setIsExploreMore(false)} className="plus"
                >
                    {isexploremore ? "explore more" : "+"}</motion.p>
            </motion.div> */}
    </div>
  );
};

export default BadgesSection;
