import "./index.css";

const About = () => {
  const values = [
    {
      title: "Privacy",
      text: "We have put in in-depth research to make it a secure platform. Your privacy matters to us and so, Highon comes with incognito mode. Say goodbye to stereotypes and unnecessary judgments and start vibing with Highon.",
    },
    {
      title: "Veracity",
      text: "We are well aware of the fake accounts, spammers, creeps, and prankers out there. But don’t worry, we got it covered for you. With Highon, get the most authentic experience with genuine people around you.",
    },
    {
      title: "Empathy",
      text: "Highon wants to protect its users from unnecessary negative publicity. To make this a safe+positive experience, we have created an interface that won’t let you down.",
    },
  ];

  return (
    <div className="aboutContainer">
      <section className="missionContainer">
        <h2>Our Mission</h2>
        <p>
          Highon wants to bring back the era when humans used to socialize in
          person. Evidently, with the changing lifestyles, people have seen to
          be flying away from homes for education and career primarily. But with
          such developments, finding that one person or group to share an event
          with similar interests has become difficult. And let’s face it, no one
          is willing to jump through hoops to find a tribe that vibes together
          right? But hang in there! Highon goes that extra mile for you. Highon
          believes in the 3Ps - People, Problem and Perspective. Hang on to the
          World’s first social vibing platform - Highon to make innumerable
          friendships and memories that count!
        </p>
      </section>
      <section className="valueContainer">
        <h2>Our Values</h2>
        {values.map((item) => {
          return (
            <section className="valueContent">
              <div className="dotContainer">
                <span className="dot"></span>
              </div>
              <h3>{item.title}</h3>
              <div>
                <span></span>
              </div>
              <div>
                <p>{item.text}</p>
              </div>
            </section>
          );
        })}
      </section>
    </div>
  );
};

export default About;
