import "./index.css";
import Question from "./faqComponent/question";
import supportPng from "../../../logosAndImages/MicrosoftTeams-image (1).png";

const Support = () => {
  const faq = [
    {
      question: "How to download Highon?",
      answer:
        "Highon is the world’s first Social Vibing platform available for free on App Store and Play Store. You can download the application on your Mobile directly by clicking the App Store or Play Store button on Highon Home Page. Happy Vibing!",
    },
    {
      question: "How to create Highon Account?",
      answer:
        "You can sign up to Highon by simply using the mobile number. If you are unable to login due to any technical issues, kindly reach us at support@highon.co.in.",
    },
    {
      question: "How do I vibe with users on Highon?",
      answer:
        `Send them a vibe request: Once they accept your vibe request, you will be able to converse with them through messaging.<br/>
        Follow other users: You’ll be able to vibe to their photos in your feed.<br/>
        Infinity chat: Stay anonymous and talk your mind out and find people who vibe with you. `,
    },
    {
      question: "What new features does Highon offer?",
      answer:
        `<b>Badge</b> - A feature that helps your followers and vibers understand your evolution on Highon. It levels up based on your app activity.<br/>
        <b>Vibe</b> - A feature that connects with people who want to connect with you. It allows you to send a direct message to a viber once you both are mutually accepting vibe requests.<br/>
        <b>Activity tab</b> - You can engage yourself in interesting activities on the app to increase your followers and vibers. This feature allows you to create social events, unofficial meetups, business promotional meetings, etc. <br/>
        <b>Infinity chat</b> - This unique feature takes you to the “Secret chat” where you will be able to connect with users based on the app’s algorithm. 
        <b>Uncluttered notifications</b> - All your notifications are segregated and arranged in the coolest way possible.`,
    },
    {
      question: "How can I upgrade my Badge on Highon?",
      answer:
        "Your badge on the profile indicates your growth on the Highon app. Based on the number of activities and the amount of time you spent on the app, the algorithm will upgrade your badge accordingly. We suggest you engage yourself in more activity on the app and be consistent in the process to get a higher-level badge.",
    },
    {
      question: "Is Highon a paid app?",
      answer:
        "No. Highon doesn’t ask you to pay for using the app. You can create your free account and enjoy access to all the features. Highon will intimate you if any extra paid service gets added to the app in the future.",
    },
    {
      question: "Are there any restrictions on the content that can be posted on Highon?",
      answer:
        "Yes. Inappropriate content like information or images that are explicit in nature, inaccurate information that might lead to unlawful or dangerous situations, and pornographic material, are prohibited.",
    },
  ];
  return (
    <div className="supportPage">
      <div className="supportHeader">
        <div className="supportText">
          <h2>Customer Support 24/7</h2>
          <span>Have any doubts or concerns? We got you covered!</span>
          <p>Here’s what you can do:</p>
          <p>
            <nbsp />- Find query in FAQ section
          </p>
          <p>
            <nbsp />- Write to us at support@highon.co.in
          </p>
          <p>Hit us up with any doubts or concerns. Happy vibing!</p>
        </div>
        <div className="vectorContainer">
          <img src={supportPng} alt="faqpng" />
        </div>
      </div>
      <div className="faqContainer" id="faqs">
        <h2>Frequently Asked Questions</h2>
        <div className="dummyLine"></div>
        <div className="questionsContainer">
          {faq.map((item) => {
            return <Question item={item} />;
          })}
        </div>
      </div>
      <div className="supportTextDuplicate">
        <h2>Customer Support 24/7</h2>
        <span>Have any doubts or concerns? We got you covered!</span>
        <p>Here’s what you can do:</p>
        <p>- Find query in FAQ section</p>
        <p>- Write to us at support@highon.co.in</p>
        <p>Hit us up with any doubts or concerns. Happy vibing!</p>
      </div>
    </div>
  );
};

export default Support;
