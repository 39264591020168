import React from "react";
import "./ZinkuSection.styles.css";
import "../../../../../../fonts.css";
import Circle from "../Asset/corousel_images/1210524.webp";
import Don from "../Asset/corousel_images/Group-8.webp";
import Coin1 from "../Asset/corousel_images/Coin.webp";
import Coin2 from "../Asset/corousel_images/Coin2.webp";
import Coin3 from "../Asset/corousel_images/Coin3.webp";
import Coin4 from "../Asset/corousel_images/Coin4.webp";

const ZinkuSectionNew = () => {
  return (
    <div className="zinkuContainer wave-container">
      <div className="dumyDiv" />
      <div className="zinContainer">
        <div className="zinkusContainer">
          <img src={Don} className="zinkuImg" alt="" />
          <img src={Coin1} className="coin1" alt="" />
          <img src={Coin2} className="coin2" alt="" />
          <img src={Coin3} className="coin3" alt="" />
          <img src={Coin4} className="coin4" alt="" />
          <div className=" circle radar1" />
          <div className="radar2" />
          <div className="radar3" />
          <div className="radar4" />
        </div>

        <div className="contentContainer">
          <div className="headerText text-gradient">
            Explore the secret spot
          </div>
          <div className="anonymously-container">
            <p className="paraText">
              Choose your favourite Zinku avatar
              <span className="firstBrake">
                <br />
              </span>
              and start{" "}
              <span className="secondBrake">
                <br />
              </span>
              vibing
              <span className="anonymously">
                <img src={Circle} className="image" alt="" />
                Anonymously
              </span>
            </p>
          </div>
          <div className="stepsContainer">
            <div className="steps">
              <div className="numContainer">1</div>
              <p className="stepText">Pick your favourite zinku</p>
            </div>
            <div className="steps">
              <div className="numContainer">2</div>
              <p className="stepText">
                Tap on the any random
                <br /> profile in the radar.
              </p>
            </div>
            <div className="steps">
              <div className="numContainer">3</div>
              <p className="stepText">
                Start chatting anonymously.
                <br /> Chats will be cleared in 48hrs
              </p>
            </div>
          </div>
        </div>
      </div>
      <svg className="wave-svg" viewBox="0 0 1440 120">
        <path
          className="wave-path"
          d="M0,60 C480,150 960,0 1440,60 L1440,120 L0,120 Z"
          fill="white"
        ></path>
      </svg>
    </div>
  );
};

export default ZinkuSectionNew;
