import React, { useState, useEffect, } from 'react'
import './vibe.styles.scss'
import { motion } from 'framer-motion'

const Vibe = () => {

    const [scroll, setScroll] = useState(0)

    const getScrollPercent = () => {
        var h = document.documentElement,
            b = document.body,
            st = "scrollTop",
            sh = "scrollHeight";

        var scrollPercent = Math.round(
            ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
        );

        setScroll(isNaN(scrollPercent) ? "" : scrollPercent);
    }

    useEffect(() => {
        window.addEventListener("scroll", getScrollPercent);

        return () => {
            window.removeEventListener("scroll", getScrollPercent);
        };
    }, []);

    useEffect(() => {
        if (scroll >= 53) {
            setView(true)
        }
        else {
            setView(false)
        }
    }, [scroll])

    const [view, setView] = useState(false);

    const useMediaQuery = (query) => {
        const [matches, setMatches] = useState(false);

        useEffect(() => {
            const media = window.matchMedia(query);
            if (media.matches !== matches) {
                setMatches(media.matches);
            }
            const listener = () => {
                setMatches(media.matches);
            };
            media.addListener(listener);
            return () => media.removeListener(listener);
        }, [matches, query]);

        return matches;
    }

    const useIsLarge = () => useMediaQuery('(min-width: 1500px)');
    const useIsMedium = () => useMediaQuery('(min-width: 900px)');
    const useIsSmall = () => useMediaQuery('(min-width: 700px)');

    const isLarge = useIsLarge()
    const isMedium = useIsMedium()
    const isSmall = useIsSmall()


    return (
        <div style={{ opacity: view ? 1 : 0 }} className='vibe-container' id="vibe">
            <motion.div className="vibe-div"
            // initial={{ scale: 0 }}
            // animate={{ scale: 1 }}
            // transition={{
            //     duration: 0.7,
            // }}
            >
                Vibe
            </motion.div>

            <motion.div className="vibe-content"
                initial={{
                    marginTop: "10%",
                    opacity: 0,
                }}
                animate={{
                    marginTop: isLarge ? "25%" : isMedium ? "23%" : isSmall ? "35%" : "35%",
                    opacity: 1,
                }}
                transition={{
                    duration: 0.7,
                }}
            >
                This tab will show you the list of vibers you have vibed with. However, the list will not be visible to anyone else except you. You can unvibe a user or send a direct message by clicking on the labels next to the user id.
            </motion.div>
        </div>
    )
}

export default Vibe;