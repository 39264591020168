import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"10%"}
    height={"70%"}
    viewBox="0 0 81 250"
    {...props}
  >
    <text
      id="_"
      data-name="\u201C"
      transform="translate(10)"
      fill="#fff"
      fontSize={200}
      fontFamily="MalayalamMN, Malayalam MN"
    >
      <tspan x={-9} y={201}>
        {"\u201C"}
      </tspan>
    </text>
  </svg>
);
export default SVGComponent;
