const JobCard = ({ item }) => {
  return (
    <>
      <div className="jobCard">
        <div className="jobRole">
          <h3>
            {item.designation} <span className="position1">{item.role}</span>
          </h3>
          <p>{item.description}</p>
        </div>
        <div className="jobApply">
          <span className="position2">{item.role}</span>
          <button>
            <span>
              <a href={item.link} target="_blank">
                Apply Now
              </a>
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default JobCard;
