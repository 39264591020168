import "./index.css";
import playStoreLogo from "../../../logosAndImages/Playstore.svg";
import appStore from "../../../logosAndImages/Appstore.svg";
import fbLogo from "../../../logosAndImages/Vector.svg";
import twitterLogo from "../../../logosAndImages/Vector-1.svg";
import linkedInLogo from "../../../logosAndImages/Vector-2.svg";
import instaLogo from "../../../logosAndImages/Vector-3.svg";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";

const Footer = () => {
  const { pathname, hash, key } = useLocation();

  const mediaLogos = [
    { logo: instaLogo, link: "https://instagram.com/highon_global" },
    {
      logo: linkedInLogo,
      link: "https://www.linkedin.com/company/highon-global/",
    },
    { logo: fbLogo, link: "https://www.facebook.com/highon.in/" },
    { logo: twitterLogo, link: "https://twitter.com/Highon_Global" },
  ];
  const storeLogo = [{logo:playStoreLogo,link:'https://play.google.com/store/apps/details?id=com.highOn.highOn'}, {logo:appStore,link:'https://apps.apple.com/in/app/highon/id6446462137'}];
  const footerText = [
    { heading: "Home", path: "/", list: [{ title: "", path: "" }] },
    {
      heading: "About us",
      path: "/aboutus",
      list: [{ title: "", path: "" }],
    },
    {
      heading: "Careers",
      path: "/career",
      list: [{ title: "Jobs", path: "/career#jobOption" }],
    },
    {
      heading: "Support",
      path: "/support",
      list: [{ title: "FAQ", path: "/support#faqs" }],
    },
    {
      heading: "Social",
      path: "",
      list: [
        {
          title: "Instagram",
          path: "https://instagram.com/highon_global",
        },
        {
          title: "LinkedIn",
          path: "https://www.linkedin.com/company/highon-global/",
        },
        {
          title: "Facebook",
          path: "https://www.facebook.com/highon.in/",
        },
        {
          title: "Twitter",
          path: "https://twitter.com/Highon_Global",
        },
      ],
    },
  ];
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <div className="footer">
      <div className="upperFooter">
        <div className="infoFooter">
          {footerText.map((item) => {
            return (
              <div>
                {item.heading !== "Social" ? (
                  <NavLink to={item.path}>
                    <h3>{item.heading}</h3>
                  </NavLink>
                ) : (
                  <h3>{item.heading}</h3>
                )}
                <ul>
                  {item.list.map((data) => {
                    if (
                      data.title !== "Instagram" &&
                      data.title !== "LinkedIn" &&
                      data.title !== "Facebbok" &&
                      data.title !== "Twitter"
                    ) {
                      return (
                        <li>
                          <NavLink to={data.path}>{data.title}</NavLink>
                        </li>
                      );
                    } else {
                      return (
                        <li>
                          <a href={data.path} target="_blank">
                            {data.title}
                          </a>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <div className="storeFooter">
          {storeLogo.map((item) => {
            return (
              <div>
                <a href={item.link} target="_blank">
                  <img src={item.logo}  alt="storelinklogo" />
                </a>
                
              </div>
            );
          })}
        </div>
      </div>
      <div className="bottomFooter">
        <div className="copyrightContent">
          <span>&#169; 2023 Highon</span>
          <ul>
          <NavLink to="/terms">
            <li>Privacy</li>
          </NavLink>
          <NavLink to="/terms">
            <li>Terms</li>
          </NavLink>
            
          </ul>
        </div>
        <div className="mediaContainer">
          {mediaLogos.map((item) => {
            return (
              <div className="logoContainer">
                <a href={item.link} target="_blank">
                  <img src={item.logo} alt="mediaicon"></img>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
