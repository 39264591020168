import React from "react";
import fivePeople from "./Assets/103.webp";
import "./TextSequencer.css";
import "../../../../fonts.css";

const TextSequencer = () => {
  return (
    <div className="knowUnknownWrapper">
      <img src={fivePeople} alt="thread" />
      <div className="knowUnknownSection">
        <h1 id="knowtheUnknowHeader">Know the Unknown</h1>
        <span style={{ textAlign: "justify", fontFamily: "Poppins Regular" }}>
          <b className="highlightText"> Socializing </b>has never been easier.
          <br />
          Meet people who truly appreciate you. <br />
          Discover <b className="highlightText">funky avatars</b>.<br /> Let
          your heart out. <br />
          Build a reliable tribe to vibe with.
          <br />
        </span>
        <br />{" "}
        <span id="knowUnknownLastSen">
          We find your tribe so that you can Vibe.
        </span>
      </div>
    </div>
  );
};

export default TextSequencer;
