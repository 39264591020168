import "./index.css";


import playStore from "../../../logosAndImages/Frame 272.svg";
import appStore from "../../../logosAndImages/Frame 273.svg";

import feedScreen from "../Home/components/Asset/Mobile screens/Feed.webp";
import profile from "../Home/components/Asset/Mobile screens/profile.webp";
import surprise from "../Home/components/Asset/Mobile screens/Surprise.webp";
import vibeBook from "../Home/components/Asset/Mobile screens/Vibebook.webp";
import Notification from "../Home/components/Asset/Mobile screens/Notification.webp";

const Download = () => {

  return (
    <>
      <div className="downloadPage">
        <div className="mainContent">
          <div className="appLink">
            <div className="linkText">
              <h2>Vibe</h2>
              <h2>with new</h2>
              <h2>people today</h2>
              <p>Highon is currently available on iOS and Android devices.</p>
            </div>
            <div className="storeLogo">
              <div className="playStore">
                <a href="https://play.google.com/store/apps/details?id=com.highOn.highOn" target='_blank'>
                  <img src={playStore} alt="playstoreimage" />
                </a>

              </div>
              <div className="appStore">
                <a href="https://apps.apple.com/in/app/highon/id6446462137" target='_blank'>
                  <img src={appStore} alt="appstoreimage" />
                </a>
              </div>
            </div>
          </div>

          <div className="iphone">
            <div>
              <img src={surprise} alt="phoneimage" />
            </div>
          </div>
          <div className="phoneZigZag">
            <div
              className="mobilCardsContainer"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "25px",
                alignItems: "flex-end",
              }}
            >
              <div
                className="mobileCards"
                style={{ height: '30vh' }}
              >
                <img
                  style={{ width: "auto", height: "50vh" }}
                  src={vibeBook}
                  alt=""
                />
              </div>
              <div
                className="mobileCards"
                style={{ height: '35vh' }}
              >
                <img
                  style={{ width: "auto", height: "50vh" }}
                  src={feedScreen}
                  alt=""
                />
              </div>
              <div
                className="mobileCards"
                style={{ height: '30vh' }}
              >
                <img
                  style={{ width: "auto", height: "50vh" }}
                  src={surprise}
                  alt=""
                />
              </div>
              <div
                className="mobileCards"
                style={{ height: '35vh' }}
              >
                <img
                  style={{ width: "auto", height: "50vh" }}
                  src={profile}
                  alt=""
                />
              </div>
              <div
                className="mobileCards"
                style={{ height: '30vh' }}
              >
                <img
                  style={{ width: "auto", height: "50vh" }}
                  src={Notification}
                  alt=""
                />
              </div>
            </div>

          </div>
        </div>
        <div className="gap"></div>
      </div>
    </>
  );
};

export default Download;
