import quoteIcon from "../../../logosAndImages/ri_double-quotes-l.png";

const FeedbackCard = ({ item }) => {
  return (
    <>
      <div className="feedbackCard">
        <div className="profileContainer">
          <img src={item.img} alt="profileimage" />
        </div>
        <div className="dummyGap">
          <div className="quoteIcon">
            <img src={quoteIcon} alt="quoteicon" />
          </div>
        </div>
        <div className="feedbackText">
          <div className="reviewPara">
            <p>{item.review}</p>
          </div>
          <div className="employee">
            <h3>{item.name}</h3>
            <h4>{item.designation}</h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackCard;
