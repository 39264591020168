import { Outlet, NavLink, Link } from "react-router-dom";
import "./Layout.css";
import logo from "../../assets/logoImg.png";
import Footer from "./pages/footerComponent/index";
import { useEffect, useRef } from "react";

const Layout = () => {
  const crossRef = useRef(null);
  const cloneRef = useRef(null);
  const navRef = useRef(null);
  const sidebarRef = useRef(null);
  const navbarOption = [
    { title: "Home", path: "/" },
    { title: "About us", path: "/aboutus" },
    { title: "Career", path: "/career" },
    { title: "Support", path: "/support" },
    { title: "Download", path: "/download" },
  ];
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 0 && window.innerWidth >= 720) {
        cloneRef.current.style.display = "block";
        navRef.current.style.position = "fixed";
        navRef.current.style.top = 0;
        navRef.current.style.backgroundColor = "rgba(255, 255, 255, 0.8)";
        navRef.current.style.backdropFilter = "blur(8px)";
        navRef.current.style.boxShadow =
          "0px 10px 40px 0 rgba(255, 255, 255, 0.8) inset";
      } else if (window.scrollY >= 0 && window.innerWidth <= 720) {
        cloneRef.current.style.display = "block";
        navRef.current.style.position = "fixed";
        navRef.current.style.top = 0;
        navRef.current.style.backgroundColor = "white";
        navRef.current.style.backdropFilter = "";
        navRef.current.style.boxShadow = "";
        navRef.current.style.height = "5rem";
      } else {
        navRef.current.style.position = "relative";
        cloneRef.current.style.display = "none";
        navRef.current.style.height = "5rem";
        navRef.current.style.backgroundColor = "white";
        navRef.current.style.backdropFilter = "";
        navRef.current.style.boxShadow = "";
      }
    });
    return window.removeEventListener("scroll", () => {});
  }, []);

  // for changing cross sign and showing nav menu
  const changeBars = () => {
    if (crossRef.current.className.includes("change")) {
      crossRef.current.className = "navbar2";
      sidebarRef.current.style.height = "0rem";
    } else {
      crossRef.current.className = "navbar2 change";
      sidebarRef.current.style.height = "calc(100vh - 5rem)";
    }
  };

  // for closing nav menu
  const closeSidebar = () => {
    crossRef.current.className = "navbar2";
    sidebarRef.current.style.height = "0rem";
  };

  return (
    <>
      <div className="layoutContainer">
        <div className="header" ref={navRef}>
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="HighOnLogo" />
            </Link>
          </div>
          <div className="navbar2" ref={crossRef} onClick={changeBars}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="navbar1">
            <nav>
              <ul>
                {navbarOption.map((item) => {
                  return (
                    <li>
                      <NavLink
                        to={item.path}
                        style={({ isActive }) => ({
                          color: isActive ? "#10AEFF" : "#464646",
                          textDecoration: "none",
                          borderBottom: isActive
                            ? "0.25rem solid #10AEFF"
                            : "none",
                        })}
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>
        </div>
        <div className="navFilter" ref={cloneRef}></div>
        <div className="sidebar" ref={sidebarRef}>
          <nav>
            <ul>
              {navbarOption.map((item) => {
                return (
                  <li>
                    <NavLink
                      to={item.path}
                      onClick={closeSidebar}
                      style={({ isActive }) => ({
                        color: isActive ? "#10AEFF" : "#464646",
                        textDecoration: "none",
                        borderBottom: isActive
                          ? "0.25rem solid #10AEFF"
                          : "none",
                      })}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </nav>
          <div className="lineMark">
            <span></span>
          </div>
        </div>
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
