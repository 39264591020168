import React from "react";
import AllMobileScreens from "./components/AllMobileScreens";
import AnimatedCorousel from "./components/Carousel";
import TopSection from "./components/TopSection";
// import ZinkuSection from "./components/ZinkuSection";
import BadgesSection from "./components/badges/badgeSection.component";
import TextSequencer from "./TextSequencer";
import VibeWithUsSequencer from "./VibeWithUsSequencer";
import ZinkuSectionNew from "./components/ZinkuSection/ZinkuSectionNew";
import Testimonial from "./components/testimonial/testimonial";

const HomePageNew = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <TopSection />
      <TextSequencer />
      <AnimatedCorousel />
      <BadgesSection />
      <ZinkuSectionNew />
      <Testimonial />
      <VibeWithUsSequencer />
      <AllMobileScreens />
    </div>
  );
};

export default HomePageNew;
