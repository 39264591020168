import A0 from "./001.png";
import A1 from "./002.png";
import A2 from "./003.png";
import A3 from "./004.png";
import A4 from "./005.png";
import A5 from "./006.png";
import A6 from "./007.png";
import A7 from "./008.png";
import A8 from "./009.png";
import A9 from "./010.png";

import A10 from "./011.png";
import A11 from "./012.png";
import A12 from "./013.png";
import A13 from "./014.png";
import A14 from "./015.png";
import A15 from "./016.png";


import A16 from "./017.png";
import A17 from "./018.png";
import A18 from "./019.png";
import A19 from "./020.png";

import A20 from "./021.png";
import A21 from "./022.png";
import A22 from "./023.png";
import A23 from "./024.png";
import A24 from "./025.png";
import A25 from "./026.png";
import A26 from "./027.png";
import A27 from "./028.png";
import A28 from "./029.png";
import A29 from "./030.png";

import A30 from "./031.png";
import A31 from "./032.png";
import A32 from "./033.png";
import A33 from "./034.png";
import A34 from "./035.png";
import A35 from "./036.png";
import A36 from "./037.png";
import A37 from "./038.png";
import A38 from "./039.png";
import A39 from "./040.png";

import A40 from "./041.png";
import A41 from "./042.png";
import A42 from "./043.png";
import A43 from "./044.png";
import A44 from "./045.png";
import A45 from "./046.png";
import A46 from "./047.png";
import A47 from "./048.png";
import A48 from "./049.png";
import A49 from "./050.png";

import A50 from "./051.png";
import A51 from "./052.png";
import A52 from "./053.png";
import A53 from "./054.png";
import A54 from "./055.png";
import A55 from "./056.png";
import A56 from "./057.png";
import A57 from "./058.png";
import A58 from "./059.png";
import A59 from "./060.png";

import A60 from "./061.png";
import A61 from "./062.png";
import A62 from "./063.png";
import A63 from "./064.png";
import A64 from "./065.png";
import A65 from "./066.png";
import A66 from "./067.png";
import A67 from "./068.png";
import A68 from "./069.png";
import A69 from "./070.png";

import A70 from "./071.png";
import A71 from "./072.png";
import A72 from "./073.png";
import A73 from "./074.png";
import A74 from "./075.png";
import A75 from "./076.png";
import A76 from "./077.png";
import A77 from "./078.png";
import A78 from "./079.png";
import A79 from "./080.png";

import A80 from "./081.png";
import A81 from "./082.png";
import A82 from "./083.png";
import A83 from "./084.png";
import A84 from "./085.png";
import A85 from "./086.png";
import A86 from "./087.png";
import A87 from "./088.png";
import A88 from "./089.png";
import A89 from "./090.png";

import A90 from "./091.png";
import A91 from "./092.png";
import A92 from "./093.png";
import A93 from "./094.png";
import A94 from "./095.png";
import A95 from "./096.png";
import A96 from "./097.png";
import A97 from "./098.png";
import A98 from "./099.png";
import A99 from "./100.png";

import A100 from "./101.png";
import A101 from "./102.png";
import A102 from "./103.png";
import A103 from "./103.png";
// import A104 from "./105.png";
// import A105 from "./106.png";
// import A106 from "./107.png";
// import A107 from "./108.png";
// import A108 from "./109.png";
// import A109 from "./110.png";

// import A110 from "./111.png";
// import A111 from "./112.png";
// import A112 from "./113.png";
// import A113 from "./114.png";
// import A114 from "./115.png";
// import A115 from "./116.png";
// import A116 from "./117.png";
// import A117 from "./118.png";
// import A118 from "./119.png";
// import A119 from "./120.png";

// import A120 from "./121.png";
// import A121 from "./122.png";
// import A122 from "./123.png";

export const AirPods = Array.of(
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,
  A10,
  A11,
  A12,
  A13,
  A14,
  A15,
  A16,
  A17,
  A18,
  A19,

  A20,
  A21,
  A22,
  A23,
  A24,
  A25,
  A26,
  A27,
  A28,
  A29,

  A30,
  A31,
  A32,
  A33,
  A34,
  A35,
  A36,
  A37,
  A38,
  A39,

  A40,
  A41,
  A42,
  A43,
  A44,
  A45,
  A46,
  A47,
  A48,
  A49,

  A50,
  A51,
  A52,
  A53,
  A54,
  A55,
  A56,
  A57,
  A58,
  A59,

  A60,
  A61,
  A62,
  A63,
  A64,
  A65,
  A66,
  A67,
  A68,
  A69,

  A70,
  A71,
  A72,
  A73,
  A74,
  A75,
  A76,
  A77,
  A78,
  A79,

  A80,
  A81,
  A82,
  A83,
  A84,
  A85,
  A86,
  A87,
  A88,
  A89,

  A90,
  A91,
  A92,
  A93,
  A94,
  A95,
  A96,
  A97,
  A98,
  A99,

  A100,
  A101,
  A102,
  A103,
  // A104,
  // A105,
  // A106,
  // A107,
  // A108,
  // A109,

  // A110,
  // A111,
  // A112,
  // A113,
  // A114,
  // A115,
  // A116,
  // A117,
  // A118,
  // A119,

  // A120,
  // A121,
  // A122,
);
